import { apiBasePath } from "@/config";
import { showSuccess, showApiErrorResponse } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import BN from "bn.js";
import { CopyTraderAccountStatistics } from "../accounts/store";

export type CopyTradeId = string;

export enum CopyTradeState {
  ONGOING = "ongoing",
  PAUSED = "paused",
  STOPPED = "stopped",
}

export type RestCopyTradeConfigurationCreateRequest = {
  chain: string;
  copiedAccount: string;
  account: string;
  balanceRatio?: number;
  minimumAmountPerSnipe?: string;
  maximumAmountPerSnipe?: string;
};

export type RestCopyTradeConfiguration =
  RestCopyTradeConfigurationCreateRequest & {
    id: CopyTradeId;
    state: CopyTradeState;
    userId: string;
    createdAt: Date;
  };

export type RestCopyTradeConfigurationUpdateRequest = {
  state: CopyTradeState;
};

export type CopyTradeConfiguration = {
  id: CopyTradeId;
  state: CopyTradeState;
  chain: string;
  userId: string;
  copiedAccount: string;
  account: string;
  balanceRatio?: number;
  minimumAmountPerSnipe?: BN;
  maximumAmountPerSnipe?: BN;
  statistics: CopyTraderAccountStatistics;
  createdAt: Date;
};

export type CopyTradeConfigurationState = {
  items: CopyTradeConfiguration[];
};

const copyTradeConfigurationState: CopyTradeConfigurationState = {
  items: [],
};

const copyTradeConfigurationGetters: GetterTree<
  CopyTradeConfigurationState,
  RootState
> = {
  items(state) {
    return state.items;
  },
  itemsOnChain(state, _getters, rootState) {
    if (!rootState.chain) {
      return [];
    }

    const items = state.items.filter((s) => s.chain === rootState.chain);

    items.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    return items;
  },
};

const copyTradeConfigurationMutations: MutationTree<CopyTradeConfigurationState> =
  {
    set(state, payload: RestCopyTradeConfiguration[]) {
      state.items = payload.map((s) => {
        const result: any = {
          ...s,
          createdAt: new Date(s.createdAt),
        };

        if (s.minimumAmountPerSnipe) {
          result.minimumAmountPerSnipe = new BN(s.minimumAmountPerSnipe);
        }

        if (s.maximumAmountPerSnipe) {
          result.maximumAmountPerSnipe = new BN(s.maximumAmountPerSnipe);
        }

        return result;
      });
    },
    delete(state, payload: CopyTradeId) {
      state.items = state.items.filter((s) => s.id !== payload);
    },
  };

const copyTradeConfigurationActions: ActionTree<
  CopyTradeConfigurationState,
  RootState
> = {
  async read({ commit }) {
    try {
      const response = await axios.get(
        apiBasePath + "/copyTrade/configuration"
      );
      commit("set", response.data);
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async create({ dispatch }, payload: RestCopyTradeConfigurationCreateRequest) {
    try {
      const response = await axios.post(
        apiBasePath + "/copyTrade/configuration/",
        payload
      );
      if (response.status === 200) {
        showSuccess("copy-trade.configuration-created");

        dispatch("read");

        return true;
      }
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async update(
    { dispatch },
    payload: {
      id: CopyTradeId;
      request: RestCopyTradeConfigurationUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch(
        `${apiBasePath}/copyTrade/configuration/${payload.id}`,
        payload.request
      );
      if (response.status === 200) {
        showSuccess("copy-trade.configuration-updated");

        dispatch("read");

        return true;
      }
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  async delete({ commit, dispatch }, payload: CopyTradeId) {
    try {
      const response = await axios.delete(
        apiBasePath + `/copyTrade/configuration/${payload}`
      );
      showSuccess("copy-trade.configuration-deleted");

      if (response.status === 200) {
        commit("delete", payload);
      }

      dispatch("read");
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
};

const copyTradeConfigurationsModule: Module<
  CopyTradeConfigurationState,
  RootState
> = {
  namespaced: true,
  state: copyTradeConfigurationState,
  getters: copyTradeConfigurationGetters,
  mutations: copyTradeConfigurationMutations,
  actions: copyTradeConfigurationActions,
};

export default copyTradeConfigurationsModule;
