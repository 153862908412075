import {
  mdiDesktopMac,
  mdiWallet,
  mdiCrosshairs,
  mdiPackage,
  mdiTune,
  mdiCurrencyUsdOff,
  mdiFileDocument,
  mdiWalletGiftcard,
  mdiRobot,
  mdiAccountKey,
  mdiContentCopy,
  // XXX GIFT CODES  mdiGift,
} from "@mdi/js";
import store from "./store";
import i18n from "@/i18n";
import { allowCopyTrade } from "./featureFlags";

export default () => {
  let menus: any[] = [];

  if (!store.getters["packages/active"]) {
    menus = [
      i18n.global.t("general"),
      [
        {
          label: i18n.global.t("packages"),
          to: "/packages",
          icon: mdiPackage,
        },
        {
          label: i18n.global.t("vouchers"),
          to: "/vouchers",
          icon: mdiWalletGiftcard,
        },
        // XXX GIFT CODES  {
        // XXX GIFT CODES    to: "/giftCodes",
        // XXX GIFT CODES    label: "Gift codes",
        // XXX GIFT CODES    icon: mdiGift,
        // XXX GIFT CODES  },
      ],
    ];
  } else {
    menus = [
      i18n.global.t("dashboard"),
      [
        {
          to: "/dashboard",
          icon: mdiDesktopMac,
          label: i18n.global.t("dashboard"),
        },
        {
          to: "/accounts",
          icon: mdiWallet,
          label: i18n.global.t("accounts"),
        },
        {
          label: i18n.global.t("snipe"),
          icon: mdiCrosshairs,
          menu: [
            {
              label: i18n.global.t("active-snipes"),
              to: "/snipe/active",
            },
            {
              label: i18n.global.t("snipe-history"),
              to: "/snipe/history",
            },
          ],
        },
      ],
    ];

    if (store.getters["packages/active"].featureFlags.automaticSnipe) {
      menus[1].push({
        label: i18n.global.t("automation"),
        icon: mdiRobot,
        menu: [
          {
            label: i18n.global.t("configuration"),
            to: "/snipeAutomation/configuration",
          },
          {
            label: i18n.global.t("signals"),
            to: "/snipeAutomation/signals",
          },
        ],
      });
    }

    if (allowCopyTrade) {
      menus[1].push({
        label: i18n.global.t("copy-trade.title"),
        icon: mdiContentCopy,
        to: "/copyTrade",
      });
    }

    menus[1] = menus[1].concat([
      {
        label: i18n.global.t("presets"),
        icon: mdiTune,
        to: "/presets/list",
      },
      {
        label: i18n.global.t("fees"),
        to: "/fees",
        icon: mdiCurrencyUsdOff,
      },
      {
        label: i18n.global.t("packages"),
        to: "/packages",
        icon: mdiPackage,
      },
      {
        label: i18n.global.t("vouchers"),
        to: "/vouchers",
        icon: mdiWalletGiftcard,
      },
      // XXX GIFT CODES  {
      // XXX GIFT CODES    to: "/giftCodes",
      // XXX GIFT CODES    label: "Gift codes",
      // XXX GIFT CODES    icon: mdiGift,
      // XXX GIFT CODES  },
      {
        label: i18n.global.t("user-guide.title"),
        icon: mdiFileDocument,
        menu: [
          {
            label: i18n.global.t("general"),
            to: "/docs/general",
          },
          {
            label: i18n.global.t("authentication"),
            to: "/docs/authentication",
          },
          {
            label: i18n.global.t("account-management"),
            to: "/docs/account",
          },
          {
            label: i18n.global.t("presets"),
            to: "/docs/preset",
          },
          {
            label: i18n.global.t("sniping"),
            to: "/docs/snipe",
          },
          {
            label: i18n.global.t("deposit"),
            to: "/docs/deposit",
          },
          {
            label: i18n.global.t("withdrawal"),
            to: "/docs/withdrawal",
          },
          {
            label: i18n.global.t("packages"),
            to: "/docs/package",
          },
          {
            label: i18n.global.t("fees"),
            to: "/docs/fee",
          },
          {
            label: i18n.global.t("copy-trade.title"),
            to: "/docs/copyTrade",
          },
        ],
      },
    ]);
  }

  if (store.state.userRoles.includes("admin")) {
    menus.push([
      {
        label: i18n.global.t("administration.title"),
        icon: mdiAccountKey,
        menu: [
          {
            label: i18n.global.t("user"),
            to: "/admin/user",
          },
          {
            label: i18n.global.t("notifications.title"),
            to: "/admin/notification",
          },
        ],
      },
    ]);
  }

  return menus;
};
